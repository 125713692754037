import React, { useEffect, useState, useContext } from 'react';
import { mutations } from '../../../graphql';
import { useMutation } from '@apollo/client';
import {
  Grid,
  FormControl,
  TextField,
  InputLabel,
  MenuItem,
  Select,
  Button,
  FormControlLabel,
  Paper,
  ListItemText,
  Checkbox,
  CircularProgress
} from '@mui/material';
import ImageUploader from '../../../Components/ImageUploader.js';
import AdminBackButton from '../../../Components/AdminBackButton.js';
import { BBSnackbarContext } from '../../../Components/BBSnackbar/BBSnackbarContext.js';
import { stripTypeNameFromMutationVars } from '../../../helpers/gql.js';
import { getTagsByGroup, extractTagIds, populateSelectedTags } from '../../../helpers/tag.js';
import { formatS3FilePath } from '../../../helpers/file.js';
import { subscriptionContainsPublishedArticle } from '../../../helpers/subscription.js';
import { UserContext } from '../../../Context';

// Select dropdown for tags
const MultiSelectDropdown = ({ title, data, selectedItemIds, setSelectedItemIds }) => {
  const handleChange = (event) => {
    setSelectedItemIds(event.target.value); // update the selectedItemIds with the selected IDs
  };
  // the value of menuItem is what gets passed into handleChange
  return (
    <FormControl variant='outlined' fullWidth>
      <InputLabel>{title}</InputLabel>
      <Select
        multiple
        value={selectedItemIds} // this is now an array of IDs passed as the value
        onChange={handleChange}
        label={title}
        renderValue={(selected) =>
          selected.map((id) => data.find((item) => item.id === id)?.name).join(', ')}
      >
        {data.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            <Checkbox checked={selectedItemIds.indexOf(item.id) > -1} />
            <ListItemText primary={item.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

// Select dropdown for authors and prices
function SelectDropdown ({ data, keyName, valueName, title, onChange, value, disabled }) {
  return (
    <FormControl fullWidth>
      <InputLabel>{title}</InputLabel>
      <Select
        onChange={onChange}
        value={value}
        label={title}
        disabled={disabled}
        style={{ backgroundColor: disabled && '#eee' }}
      >
        {data.map(item => (
          <MenuItem key={item[valueName]} value={item[valueName]}>
            {item[keyName]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default function SubscriptionForm ({
  subscription,
  authors,
  prices,
  onSave,
  tags
}) {
  const [currentSubscription, setCurrentSubscription] = useState({
    ...subscription,
    selectedSystemTagIds: extractTagIds(getTagsByGroup(subscription.tags, 'System', null)),
    selectedDifficultyTagIds: extractTagIds(getTagsByGroup(subscription.tags, 'Difficulty', null)),
    selectedTypeTagIds: extractTagIds(getTagsByGroup(subscription.tags, 'Type', null)),
    selectedGeneralTagIds: extractTagIds(getTagsByGroup(subscription.tags, null, null))
  });

  const [imagePath, setImagePath] = useState(subscription.images && subscription.images.cover);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { user } = useContext(UserContext);

  // images
  const handleImageUploadComplete = (filename, folder) => {
    setImagePath(formatS3FilePath(filename, folder));
  };

  const handleUploadError = (message) => {
    const { onSubmitError } = this.props;
    onSubmitError(message);
  };

  // validating for save subscription button
  const validate = (subscription) => {
    let isValid = false;
    if (subscription) {
      if (
        subscription.title &&
        subscription.title.length > 0 &&
        subscription.description &&
        subscription.description.length > 0 &&
        subscription.author &&
        subscription.monthlyPriceId &&
        subscription.yearlyPriceId &&
        subscription.selectedGeneralTagIds && subscription.selectedGeneralTagIds.length > 0 &&
        subscription.selectedSystemTagIds && subscription.selectedSystemTagIds.length > 0 &&
        subscription.selectedTypeTagIds && subscription.selectedTypeTagIds.length > 0 &&
        subscription.selectedDifficultyTagIds && subscription.selectedDifficultyTagIds.length > 0
      ) {
        isValid = true;
      }
    }
    return isValid;
  };

  useEffect(() => {
    setIsFormValid(validate(currentSubscription));
  }, [currentSubscription]);

  // tags
  const difficultyTags = getTagsByGroup(tags, 'Difficulty', 'en-gb');
  const typeTags = getTagsByGroup(tags, 'Type', 'en-gb');
  const systemTags = getTagsByGroup(tags, 'System', 'en-gb');
  const generalTags = getTagsByGroup(tags, null, 'en-gb');

  // handlers
  const handleTagChange = (selectedTagIds, group) => {
    setCurrentSubscription(prevState => ({
      ...prevState,
      [`selected${group}TagIds`]: selectedTagIds
    }));
  };

  const handleSwitchChange = (e) => {
    setCurrentSubscription(prevState => ({
      ...prevState,
      onSale: e.target.checked
    }));
  };

  const handleSubscriptionChange = (e) => {
    const updatedSubscription = { ...currentSubscription };
    updatedSubscription[e.target.name] = e.target.value;
    setCurrentSubscription(updatedSubscription);
  };

  const handleAuthorChange = (e) => {
    setCurrentSubscription(prevState => ({
      ...prevState,
      authorId: e.target.value,
      author: { id: e.target.value }
    }));
  };

  const handleMonthlyChange = (e) => {
    setCurrentSubscription(prevState => ({
      ...prevState,
      monthlyPriceId: e.target.value
    }));
  };

  const handleYearlyChange = (e) => {
    setCurrentSubscription(prevState => ({
      ...prevState,
      yearlyPriceId: e.target.value
    }));
  };

  // const handleArticleClick = (id) => {
  //   console.log('Article clicked:', id);
  // };

  // const articlesForCalendar = currentSubscription.articles?.map((article) => ({
  //   id: article.id,
  //   date: article.publishDateTimeUniversal.split(' ')[0],
  //   title: article.title
  // }));

  // sending data back to server after save
  const [upsertSubscription] = useMutation(mutations.UpsertSubscription);
  const { openSnackbar } = useContext(BBSnackbarContext);

  const handleSaveClick = async () => {
    // check to make sure there is a published article before allowing the subscription
    // to go on sale
    if (currentSubscription.onSale && !subscriptionContainsPublishedArticle(subscription)) {
      alert('Subscription must contain at least one published article before going on sale');
    } else {
      const allSelectedTags = [
        ...populateSelectedTags(currentSubscription.selectedSystemTagIds, systemTags),
        ...populateSelectedTags(currentSubscription.selectedDifficultyTagIds, difficultyTags),
        ...populateSelectedTags(currentSubscription.selectedTypeTagIds, typeTags),
        ...populateSelectedTags(currentSubscription.selectedGeneralTagIds, generalTags)
      ];
      const updatedSubscription = {
        ...currentSubscription,
        tags: allSelectedTags,
        images: {
          cover: imagePath
        }
      };

      setIsLoading(true);

      let response;
      try {
        response = await upsertSubscription({
          variables: stripTypeNameFromMutationVars(updatedSubscription)
        });
        setIsLoading(false);
        openSnackbar('Saved successfully', 'success');
      } catch (e) {
        setIsLoading(false);
        openSnackbar(e.message, 'error');
      }

      if (onSave) {
        onSave(response.data.upsertSubscription);
      }
    }
  };

  if (!currentSubscription || !authors || !prices || !tags) return null;

  return (
    <Paper style={{ padding: 20 }}>
      <Grid
        container
        spacing={2}
        alignItems='center'
        justifyContent='center'
        rowSpacing={2}
      >
        <Grid item xs={11}>
          <AdminBackButton destination='/admin/subscriptions' />
        </Grid>
        <Grid item xs={11}>
          <SelectDropdown
            title='Author *'
            name='author'
            data={authors}
            onChange={handleAuthorChange}
            keyName='fullName'
            valueName='id'
            value={currentSubscription.author.id}
          />
        </Grid>
        <Grid item xs={11}>
          <TextField
            fullWidth
            name='title'
            label='Title *'
            value={currentSubscription.title}
            onChange={handleSubscriptionChange}
            error={currentSubscription.title === ''}
            helperText={currentSubscription.title === '' ? 'Required field' : ''}
          />
        </Grid>
        <Grid item xs={11}>
          <TextField
            fullWidth
            name='description'
            label='Description *'
            maxRows={4}
            minRows={4}
            multiline
            value={currentSubscription.description}
            onChange={handleSubscriptionChange}
            error={currentSubscription.description === ''}
            helperText={currentSubscription.description === '' ? 'Required field' : ''}
          />
        </Grid>
        <Grid item xs={3.67}>
          <MultiSelectDropdown
            title='System *'
            data={systemTags}
            selectedItemIds={currentSubscription.selectedSystemTagIds}
            setSelectedItemIds={(ids) => handleTagChange(ids, 'System')}
          />
        </Grid>
        <Grid item xs={3.67}>
          <MultiSelectDropdown
            title='Difficulty *'
            data={difficultyTags}
            selectedItemIds={currentSubscription.selectedDifficultyTagIds}
            setSelectedItemIds={(ids) => handleTagChange(ids, 'Difficulty')}
          />
        </Grid>
        <Grid item xs={3.67}>
          <MultiSelectDropdown
            title='Type *'
            data={typeTags}
            selectedItemIds={currentSubscription.selectedTypeTagIds}
            setSelectedItemIds={(ids) => handleTagChange(ids, 'Type')}
          />
        </Grid>
        <Grid item xs={11}>
          <MultiSelectDropdown
            title='Select tags *'
            data={generalTags}
            selectedItemIds={currentSubscription.selectedGeneralTagIds}
            setSelectedItemIds={(ids) => handleTagChange(ids, 'General')}
          />
        </Grid>

        <Grid item xs={5.5}>
          <SelectDropdown
            title='Yearly Price *'
            name='subscriptionPlans'
            value={currentSubscription.yearlyPriceId}
            onChange={handleYearlyChange}
            data={prices}
            keyName='priceUSD'
            valueName='id'
            disabled={subscription.id !== undefined}
          />
        </Grid>
        <Grid item xs={5.5}>
          <SelectDropdown
            title='Monthly Price *'
            name='subscriptionPlans'
            value={currentSubscription.monthlyPriceId}
            onChange={handleMonthlyChange}
            data={prices}
            keyName='priceUSD'
            valueName='id'
            disabled={subscription.id !== undefined}
          />
        </Grid>
        <Grid item xs={11}>
          <ImageUploader
            key='image'
            id='image'
            uploadFolder='images'
            currentImageSrc={imagePath}
            onUploadComplete={handleImageUploadComplete}
            onUploadError={handleUploadError}
          />
        </Grid>
        {
          subscription.id && user.type === 'Admin' && (
            <Grid item xs={11}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={currentSubscription.onSale}
                    onChange={handleSwitchChange}
                    value='onSale'
                  />
                }
                label='On sale'
              />
            </Grid>
          )
        }
        <Grid item xs={11}>
          {isLoading
            ? (
              <CircularProgress size={24} />
              )
            : (
              <Button
                disabled={!isFormValid}
                variant='contained'
                onClick={handleSaveClick}
              >
                Save subscription
              </Button>
              )}
        </Grid>
        <Grid item xs={11}>
          <div />
        </Grid>
      </Grid>
    </Paper>
  );
}
