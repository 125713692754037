import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { Tooltip, Typography, Grid, IconButton } from '@mui/material';
import BackIcon from '@mui/icons-material/KeyboardBackspace';
import Compass from '../../../../Components/Hands/Compass.js';
import TrickCounts from '../../../../Components/Hands/TrickCounts.js';
import Contract from '../../../../Components/Hands/Contract/Contract.js';
import BBTranslate from '../../../../Components/Locale/BBTranslate.js';
import { HandContext } from '../../../../Context/HandContext.js';
import useOrientation from '../../../../Hooks/useOrientation.js';

const useStyles = makeStyles((theme) => ({
  header: {
    position: 'relative',
    height: '7rem',
    paddingRight: '2rem',
    background: '#232a2c',
    color: '#fff',
    zIndex: 1,
    '&:after': {
      content: '""',
      position: 'absolute',
      left: 0,
      bottom: '-1rem',
      right: 0,
      height: '1rem',
      background: 'radial-gradient(farthest-side at 50% 0, rgba(255,255,255,0.1), transparent)',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100% 1rem',
      pointerEvents: 'none'
    }
  },
  headerMobile: {
    height: '5rem',
    padding: 0
  },
  backButton: {
    float: 'left',
    color: '#fff',
    opacity: 0.5,
    '&:hover': {
      background: 'transparent',
      opacity: 1
    }
  },
  logo: {
    width: '15rem',
    cursor: 'pointer',
    '& img': {
      width: '100%',
      verticalAlign: 'middle'
    }
  },
  logoMobile: {
    width: '5rem',
    padding: '0 1rem',
    cursor: 'pointer',
    '& img': {
      width: '100%',
      verticalAlign: 'middle'
    }
  },
  openAuctionButton: {
    border: '1px solid rgba(255,255,255,0.2)',
    borderRadius: '0.5rem',
    padding: '0 1rem',
    cursor: 'pointer',
    '&:hover': {
      borderColor: 'rgba(255,255,255,0.4)'
    }
  },
  vul: {
    lineHeight: '1.8rem',
    cursor: 'default'
  },
  tricks: {
    marginRight: '1rem'
  },
  version: {
    fontSize: 13,
    margin: '5px 0 0 10px'
  }
}));

export default function PlayHeader ({ onContractClick, auctionComplete, tricks, isPreview }) {
  const classes = useStyles();

  const isPortrait = useOrientation();

  const {
    purchaseType,
    handJSON,
    isEmbed,
    handleReturnToParent
  } = useContext(HandContext);

  const {
    vulnerable,
    viewpoint,
    contract,
    declarer
  } = handJSON;

  let vulnTextId;

  if (vulnerable === 'NS') {
    vulnTextId = 'ns-north-south';
  } else if (vulnerable === 'EW') {
    vulnTextId = 'ew-east-west';
  } else if (vulnerable === 'Both' || vulnerable === 'All') {
    vulnTextId = 'vulnerable-both';
  } else if (vulnerable === 'None') {
    vulnTextId = 'vulnerable-none';
  } else {
    throw new Error('Unexpected vulnerable value: ' + vulnerable);
  }

  return (
    <div
      className={clsx(classes.header, isPortrait && classes.headerMobile)}
      id='playHeader'
    >
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        wrap='nowrap'
        style={{ height: '100%' }}
      >
        <Grid item>
          <Grid
            container
            direction='row'
            justifyContent='flex-start'
            alignItems='center'
          >
            {
              isPortrait
                ? (
                  <Grid item className={classes.logoMobile} onClick={!isEmbed ? handleReturnToParent : null}>
                    <img src='/img/components/header/logo_icon.svg' alt='bridgebee logo' />
                  </Grid>
                  )
                : (
                  <>
                    <Grid item>
                      {
                        !isEmbed && !isPreview
                          ? (
                            <Tooltip title={
                              <Typography>
                                <BBTranslate
                                  textId={purchaseType === 'product' ? 'back-to-product' : 'back-to-article'}
                                />
                              </Typography>
                              }
                            >
                              <IconButton
                                variant='contained'
                                color='primary'
                                className={classes.backButton}
                                onClick={handleReturnToParent}
                              >
                                <BackIcon />
                              </IconButton>
                            </Tooltip>
                            )
                          : null
                      }
                    </Grid>
                    {
                      !isPreview && (
                        <Grid
                          item
                          className={classes.logo}
                          style={{ marginLeft: isEmbed && '2rem' }}
                          onClick={!isEmbed ? handleReturnToParent : null}
                        >
                          <img src='/img/components/footer/logo.svg' alt='bridgebee logo' />
                        </Grid>
                      )
                    }
                  </>
                  )
            }
            <span className={classes.version} style={{ marginLeft: isPreview ? 210 : 10 }}>v{global.appVersion}</span>
          </Grid>
        </Grid>

        <Grid item xs>

          <Grid
            container
            direction='row'
            justifyContent='flex-end'
            alignItems='center'
            wrap='nowrap'
            spacing={isPortrait ? 2 : 3}
          >
            {
              auctionComplete && (
                <Grid item>
                  <Tooltip title={<Typography><BBTranslate textId='view-auction' /></Typography>}>
                    <div className={classes.openAuctionButton} onClick={onContractClick}>
                      <Contract
                        contract={contract}
                        declarer={declarer}
                        theme='grey'
                      />
                    </div>
                  </Tooltip>
                </Grid>
              )
            }
            <Grid item className={classes.vul}>
              <BBTranslate textId='vul-vulnerable' />:<br />
              <BBTranslate textId={vulnTextId} />
            </Grid>
            <Grid item className={classes.tricks}>
              <Compass viewpoint={viewpoint}>
                {
                  auctionComplete
                    ? <TrickCounts
                        tricks={tricks}
                        size='large'
                      />
                    : null
                }
              </Compass>
            </Grid>
          </Grid>

        </Grid>

      </Grid>

    </div>
  );
}

PlayHeader.propTypes = {
  auctionComplete: PropTypes.bool.isRequired,
  tricks: PropTypes.object.isRequired,
  onContractClick: PropTypes.func.isRequired
};
