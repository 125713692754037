import React from 'react';
import { styled } from '@mui/styles';
import {
  Box,
  Typography
} from '@mui/material';
import LocalisedDisplayDate from '../../../../../Components/Locale/LocalisedDisplayDate.js';
import ArrowIcon from '@mui/icons-material/ArrowForward.js';

const ArticlesBox = styled(Box)(({ theme }) => ({
  overflow: 'auto',
  height: 500,
  marginTop: 20,
  [theme.breakpoints.down('sm')]: {
    height: 'auto'
  }
}));

const ArticleBox = styled(Box)(({ theme, selected }) => ({
  position: 'relative',
  borderBottom: '1px solid #BFBFBF',
  backgroundColor: selected ? theme.palette.common.oatmeal : theme.palette.background.default,
  borderRadius: '4px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  padding: '3rem 1rem',
  minHeight: 66,
  textDecoration: 'none',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.common.oatmeal
  }
}));

const IconBox = styled(Box)({
  position: 'absolute',
  right: 10,
  top: 38
});

export default function ArticleList ({ availableArticles, selectedArticleId, onArticleClick }) {
  return (
    <ArticlesBox>
      {
        availableArticles && availableArticles.map(article => {
          const isSelected = selectedArticleId === article.publicId;
          return (
            <ArticleBox
              key={article.id}
              selected={isSelected}
              onClick={() => onArticleClick(article)}
            >
              <Typography variant='h4'>
                {article.title}
              </Typography>
              <Typography variant='body3'>
                <LocalisedDisplayDate
                  date={article.publishDateTime}
                />
              </Typography>
              {
                isSelected
                  ? (
                    <IconBox>
                      <ArrowIcon />
                    </IconBox>
                    )
                  : null
              }
            </ArticleBox>
          );
        })
      }
    </ArticlesBox>
  );
}
