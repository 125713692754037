import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import {
  Modal,
  Button
 } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Hand from '../../../Customer/Store/Hand/Hand.js';
import HooksWrapper from '../../../../Components/HooksWrapper.js';
import {
  UserContext,
  HandContext
} from '../../../../Context';

const useStyles = makeStyles((theme) => ({
  button: {
    position: 'absolute',
    top: theme.spacing(2.5),
    left: theme.spacing(2)
  },
  close: {
    marginRight: theme.spacing(1)
  }
}));

const PreviewModal = ({ open, onCloseClick, hand, parent, subscription, startStep }) => {
  const classes = useStyles();

  const { user } = useContext(UserContext);
  const { init: initHandContext } = useContext(HandContext);

  useEffect(() => {
    initHandContext({
      hand,
      parent,
      grandParent: subscription,
      nextHandUrl: '',
      currentUser: user,
      isEmbed: false,
      isPreview: true,
      onHandleReturnToParent: () => {
        onCloseClick();
      }
    });
  }, [initHandContext, hand, parent, subscription, user, onCloseClick]);

  return (
    <Modal
      open={open}
    >
      <>
        <div style={{ position: 'absolute', top: 15, left: 15, zIndex: 1000 }}>
          <Button color='secondary' onClick={onCloseClick} variant='contained'>
            <CloseIcon style={{ marginRight: 10}} />
            Close Preview
          </Button>
        </div>
        <div style={{ height: '100%' }} className={classes.root}>
          <HooksWrapper>
            {({ isPortrait, componentScale, screenDimensions, aspectRatio }) => (
              <Hand
                isPreview
                hand={hand}
                isPortrait={isPortrait}
                componentScale={componentScale}
                screenDimensions={screenDimensions}
                aspectRatio={aspectRatio}
                startStep={startStep}
              />
            )}
          </HooksWrapper>
        </div>
      </>
    </Modal>
  );
};

PreviewModal.propTypes = {
  onCloseClick: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  hand: PropTypes.object
};

export default PreviewModal;
